import React, { useEffect } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> February 5, 2025</p>
        
        <h2>1. Introduction</h2>
        <p>Welcome to NeoNimble.ai ("we," "our," or "us"). We are committed to protecting your privacy and ensuring the security of your personal information.</p>
        
        <h2>2. Information We Collect</h2>
        <ul>
          <li><strong>Personal Information:</strong> Name, email, and account details.</li>
          <li><strong>Health & Fitness Data:</strong> Data related to posture, exercise movements, and workout performance.</li>
          <li><strong>Device Information:</strong> Device model, OS version, and network details.</li>
          <li><strong>Usage Data:</strong> Interaction with app features and engagement analytics.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>To provide personalized workout feedback and exercise recommendations.</li>
          <li>To enhance and improve our services.</li>
          <li>To comply with legal obligations.</li>
        </ul>

        <h2>4. Data Sharing and Disclosure</h2>
        <p>We do not sell your data. We only share data with:</p>
        <ul>
          <li>Trusted service providers under strict confidentiality agreements.</li>
          <li>Legal authorities when required by law.</li>
        </ul>

        <h2>5. Data Security</h2>
        <p>We implement industry-standard security measures to protect your data. However, no online service is 100% secure.</p>

        <h2>6. Your Rights</h2>
        <ul>
          <li>Request access, corrections, or deletion of your personal data.</li>
          <li>Contact us at <a href="mailto:support@neonimble.ai">support@neonimble.ai</a> for data-related requests.</li>
        </ul>

        <h2>7. Children's Privacy</h2>
        <p>Our app is not intended for children under 13, and we do not knowingly collect their data.</p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy periodically. Any changes will be posted on this page.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions, reach out to us at:</p>
        <p>
          <strong>NeoNimble.ai</strong><br />
          Email: <a href="mailto:support@neonimble.ai">support@neonimble.ai</a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 