import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import NeoNimble from "./neonimble/NeoNimble";

import DoctorsPage from "./neonimble/doctorspage/doctorsPage";
import ProfilePage from "./neonimble/doctorspage/profilePage/profilePage";
import Login from "./neonimble/loginpage/login";
import ProgressReport from "./neonimble/progressreport/ProgressReport";
import DocExerciseAllocation from "./neonimble/doctorspage/docExerciseAllocation/docExerciseAllocation";
import WebPage from "./neonimble/webpage/WebPage";
import PrivacyPolicy from "./neonimble/webpage/components/privacy/PrivacyPolicy";
// import Q404 from "./neonimble/dashboard/Q404";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="*" element={<Login />} /> */}
        <Route path="patientExercise" element={<DocExerciseAllocation />} />
        <Route path="patientProgress" element={<ProgressReport />} />
        <Route path="DoctorsDashboard" element={<DoctorsPage />} />
        <Route path="patient" element={<ProfilePage />} />
        <Route path="Login" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<NeoNimble />} />
        <Route path="*" element={<NeoNimble />} />
        <Route path="/web" element={<WebPage />} />
        <Route path="/web/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="*" element={<Q404 />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
