import { useState, useEffect } from "react";
import "./header.css";
import { Link } from "react-scroll";
import logo from '../../img/your-logo.png'; // Import your logo image

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItems = [
    { title: "Home", to: "intro" },
    { title: "Why", to: "articles" },
    { title: "What", to: "vision" },
    { title: "How", to: "solutions" },
    { title: "Contact", to: "contact" }
  ];

  return (
    <header className={`webpage-header ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="webpage-header-left">
        <Link
          to="intro"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          onClick={closeMobileMenu}
          className="logo-link"
        >
          <div className="logo-container"> {/* Added container for the logo and background */}
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          neonimble.ai
        </Link>
      </div>
      
      <nav className="webpage-header-right">
        <button 
          className="mobile-menu-icon" 
          onClick={toggleMobileMenu}
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? '✕' : '☰'}
        </button>
        
        <div className={`header-menu ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
          {menuItems.map((item) => (
            <Link
              key={item.to}
              className="header-menu-item"
              activeClass="active"
              to={item.to}
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={closeMobileMenu}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;